import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import config from "../../../content/meta/config";

const Seo = props => {
  const { data, facebook } = props;
  const postTitle = ((data || {}).frontmatter || {}).title;
  const postDescription = ((data || {}).frontmatter || {}).description;
  const postCover = ((data || {}).frontmatter || {}).cover;
  const postSlug = ((data || {}).fields || {}).slug;

  const title = postTitle ? `${postTitle} - ${config.shortSiteTitle}` : config.siteTitle;
  const description = postDescription ? postDescription : config.siteDescription;
  const image = postCover ? postCover : config.siteImage;
  const url = config.siteUrl + config.pathPrefix + postSlug;
  // const url = config.siteUrl + postSlug;

  return (
    <Helmet
      htmlAttributes={{
        lang: config.siteLanguage,
        prefix: "og: http://ogp.me/ns#"
      }}
    >
      {/* General tags */}
      <title>{title}</title>
      {/* <meta name="description" content={description} /> */}
      {/* OpenGraph tags */}
      {/* <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:type" content="website" />
      <meta property="fb:app_id" content={facebook.appId} /> */}
      {/* Twitter Card tags */}
      {/* <meta name="twitter:card" content="summary" />
      <meta
        name="twitter:creator"
        content={config.authorTwitterAccount ? config.authorTwitterAccount : "jaysilvas"}
      />
      <meta property="og:image" content="https://realityquestpodcast.com/static/og-preview.jpg" /> */}

      {/* Direct Tags */}
      {/* WMR Tags Template */}
      <meta property="og:title" content="Reality Quest" />
      <meta
        name="description"
        content="We are two curious humans on a journey to explore the wild west that is Immersive Technology (otherwise known as the XR industry).A gathering place for creators, artists, researchers, and explorers working together to discover the potential of emerging technologies like virtual, augmented, and mixed reality (XR)."
      />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content="https://realityquestpodcast.com/og-preview.jpg" />
      <meta property="og:type" content="website" />
      <meta name="author" content="Jay Silvas & Kelly Campelia" />

      {/* Twitter Social Graph */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="https://realityquestpodcast.com" />
      <meta name="twitter:creator" content="@jaysilvas" />
      <meta name="twitter:title" content="Welcome to Reality Quest" />
      <meta
        name="twitter:description"
        content="We are two curious humans on a journey to explore the wild west that is Immersive Technology (otherwise known as the XR industry)."
      />
      <meta name="twitter:image" content="https://realityquestpodcast.com/og-preview.jpg" />
    </Helmet>
  );
};

Seo.propTypes = {
  data: PropTypes.object,
  facebook: PropTypes.object.isRequired
};

export default Seo;
